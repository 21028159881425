import axios from "axios";

export default {
  async getPO(slug) {
    return await axios
      .get(
        `purchase-private/private-po?fromDate=${slug.fromDate}&toDate=${slug.toDate}`
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getPODetails(id) {
    return axios
      .get(`purchase-private/private-child-by-master/${id}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async allowedAction(req) {
    axios
      .post("purchase-private/action/", req)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async poGenerate(master_id) {
    return axios
      .get("purchase-private/PO-generation/" + master_id)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
};

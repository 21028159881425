<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular
    ></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-row>
        <!-- #region Card section 1 -->
        <v-col
          cols="12"
          :class="{ 'mb-5': currentPage.permissions.length <= 0 }"
        >
          <v-card class="card-shadow border-radius-xl">
            <v-card-text class="px-0 py-0">
              <v-row class="pa-4 ms-3">
                <v-col cols="2">
                  <v-row class="tittle__row">
                    <label class="text-md text-typo ms-1">Vendor</label>
                  </v-row>
                  <v-row>
                    <label class="text-lg text-typo ms-1 label__value">{{
                      this.poConfirmation.vendor_name
                    }}</label>
                  </v-row>
                </v-col>
                <v-col cols="2">
                  <v-row class="tittle__row">
                    <label class="text-md text-typo ms-1">Warehouse</label>
                  </v-row>
                  <v-row>
                    <label class="text-lg text-typo ms-1 label__value">{{
                      this.poConfirmation.company_warehouse_name
                    }}</label>
                  </v-row>
                </v-col>

                <v-col cols="2">
                  <v-row class="tittle__row">
                    <label class="text-md text-typo ms-1">Sale Date</label>
                  </v-row>
                  <v-row>
                    <label class="text-lg text-typo ms-1 label__value">{{
                      formatDate(this.poConfirmation.sale_date)
                    }}</label>
                  </v-row>
                </v-col>
                <v-col cols="2">
                  <v-row class="tittle__row">
                    <label class="text-md text-typo ms-1">PO Number</label>
                  </v-row>
                  <v-row>
                    <label class="text-lg text-typo ms-1 label__value">{{
                      this.poConfirmation.po_number || "N/A"
                    }}</label>
                  </v-row>
                </v-col>

                <v-col cols="2">
                  <v-row class="tittle__row">
                    <label class="text-md text-typo ms-1">Status</label>
                  </v-row>
                  <v-row>
                    <label class="text-lg text-typo ms-1 label__value">{{
                      this.poConfirmation.po_gen_status
                    }}</label>
                  </v-row>
                </v-col>

                <v-col>
                  <action-button
                    v-if="poConfirmation.po_gen_status == 'PENDING'"
                    :actions="currentPage.actions_allowed"
                    @onClick="allowedAction"
                  />
                </v-col>
                <v-col>
                  <v-btn
                    @click="poGeneration"
                    elevation="0"
                    :ripple="false"
                    v-if="
                      poConfirmation.po_gen_status == 'APPROVED' &&
                      poConfirmation.po_number == (null || '')
                    "
                    height="43"
                    dark
                    class="
                      font-weight-bold
                      text-capitalize
                      btn-ls btn-primary
                      bg-success
                      py-3
                      px-6
                      mt-2
                    "
                    >Generate PO
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="poConfirmationDetails"
                item-key="id"
                fixed-header
                hide-default-footer
                class="table"
                disable-pagination
              >
                <template v-slot:[`item.invoice_weight`]="{ item }">
                  <span>
                    {{ item.total_net_kgs }}
                  </span>
                </template>
                <template v-slot:[`item.total_value`]="{ item }">
                  <span>
                    {{ item.total_net_kgs * item.purchased_rate }}
                  </span>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ActionButton from "../../../../components/ActionButton.vue";
import api from "../api";
export default {
  name: "PO Confirmation Details",
  components: {
    ActionButton,
  },
  data() {
    return {
      poConfirmation: null,
      currentPage: null,
      poConfirmationDetails: [],
      selectedData: {},
      overlay: false,
      headers: [
        {
          text: "item name",
          value: "item_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          width: 100,
          sortable: false,
        },
        {
          text: "no of packages",
          value: "no_of_packages",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          width: 150,
          sortable: false,
        },
        {
          text: "Net weight",
          value: "unit_weight",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          width: 100,
          sortable: false,
        },
        {
          text: "Invoice Weight",
          value: "invoice_weight",
          class: "text-secondary font-weight-bolder opacity-7",
          align: "center",
          width: 150,
          sortable: false,
        },
        {
          text: "purchased rate",
          value: "purchased_rate",
          class: "text-secondary font-weight-bolder opacity-7",
          align: "center",
          width: 150,
          sortable: false,
        },
        {
          text: "Total Value",
          value: "total_value",
          class: "text-secondary font-weight-bolder opacity-7",
          align: "center",
          width: 150,
          sortable: false,
        },
        // {
        //   text: "Gross Net Wight",
        //   value: "total_net_kgs",
        //   class: "text-secondary font-weight-bolder opacity-10",
        //   align: "center",
        //   width: 150,
        //   sortable: false,
        // },
        // {
        //   text: "Net Wight",
        //   value: "unit_weight",
        //   class: "text-secondary font-weight-bolder opacity-7",
        //   align: "center",
        //   width: 150,
        //   sortable: false,
        // },
      ],
    };
  },
  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      try {
        this.poConfirmation = this.$route.params.poConfirmation;
        if (this.poConfirmation) {
          this.currentPage = this.$route.params.currentPage;
          this.poConfirmationDetails = await api.getPODetails(
            this.poConfirmation.id
          );
        } else {
          this.$router.push({ name: "PO Confirmation" });
        } // console.log(this.poConfirmation);
      } catch (err) {
        this.showErrorAlert(error);
      }
    },
    goBack() {
      this.$router.go(-1);
    },

    async allowedAction(item) {
      console.log("item", item);
      this.overlay = true;
      try {
        api.allowedAction({
          id: this.poConfirmation.id,
          action: item,
        });

        this.overlay = false;
        this.showSuccessAlert("State updated");
      } catch (error) {
        this.showErrorAlert(error);
      } finally {
        this.$router.push({ name: "PO Confirmation" });
      }
    },

    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    },

    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        showConfirmButton: false,
        type: "success",
        timer: 2000,
        icon: "success",
      });
    },
    showErrorAlert(message) {
      this.$swal({
        title: "Warning",
        text: message,
        showConfirmButton: true,
        type: "error",

        icon: "error",
      });
      this.overlay = false;
    },

    async poGeneration() {
      this.overlay = true;
      let r = await api
        .poGenerate(this.poConfirmation.id)
        .then((res) => {
          this.showSuccessAlert("PO Generated");
          this.poConfirmation.po_number = res.po_number;

          setTimeout(() => {
            this.$router.push({ name: "PO Confirmation" });
          }, 1000);
          // this.$router.push({ name: "PO Confirmation" });
        })
        .catch((er) => {
          this.showErrorAlert(er);
        })
        .finally(() => {
          this.overlay = false;
        });
    },
  },
  async mounted() {
    this.poConfirmation = this.$route.params.poConfirmation;
    this.currentPage = this.$route.params.currentPage;
  },
};
</script>

<style scoped>
.v-card--link:focus::before {
  opacity: 0;
}
.qtyText {
  width: 65pt;
}
.v-text-field:hover {
  background-color: white;
}
.itemLabel {
  font-weight: 600;
}
.tittle__Row {
  margin-bottom: 1;
}
.label__value {
  font-weight: 600;
}
</style>
